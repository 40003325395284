/* eslint-disable @typescript-eslint/comma-dangle */
import axios from 'axios';
import {
  maxItemAge, clearExpiredCookies, hasValidJwt, login, getCookieValue
} from './auth';

export type KeyValue<T, U> = {
  key: T,
  value: U,
};

export type Headers = { [key: string]: string };

export type Json = {
  [K in string]: Json | null | undefined;
} | ReadonlyArray<Json> | Date | string | number | boolean | null | FormData;

export type Options = { useLongCache?: boolean, api?: string };

export class ErrorStatus extends Error {
  status: number;

  constructor(status: number, message?: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.status = status;
  }
}

const VERSION = '/api/v2';
const TIMEOUT = 2 * 60 * 1000;

const apiClient = async <T,>(
  method: string,
  url: string,
  body?: Json,
  headers?: Headers,
  options?: Options,
  isRetry = false,
  requireValidJwt = true,
): Promise<T> => {
  const params: any = {
    method,
    headers: {
      ...headers,
      accept: 'application/json',
    },
  };

  clearExpiredCookies();

  if (options && options.useLongCache) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return getWithLongCache(method);
  }

  if (requireValidJwt && !hasValidJwt()) {
    await login();
  }

  const apiPrefixExists = /^\/api\/(v1|v2|v3|billing)\/.*/.test(url);

  let resolvedUrl = apiPrefixExists
    ? url
    : options?.api ? options.api.concat(url) : VERSION.concat(url);

  if (resolvedUrl.includes('/api/insights/')) {
    const request = resolvedUrl.split('/api/insights/')[1];
    resolvedUrl = `${process.env.REACT_APP_INSIGHTS_API}${request}`;

    params.headers = {
      ...params.headers,
      Authorization: 'Bearer ' + decodeURIComponent(getCookieValue('jwtv2'))
    };
  }

  return axios({
    ...params,
    url: resolvedUrl,
    timeout: TIMEOUT,
    data: body,
  }).then((res) => res.data)
    .catch((err) => {
      if (err.response === undefined) {
        throw new ErrorStatus(1, err?.message || err);
      } else if (err.response.status === 401) {
        // Try logging in and retry
        login()
          .then(() => {
            if (isRetry === false) {
              return apiClient(
                method,
                url,
                body,
                headers,
                options,
                true,
              );
            }
            throw new ErrorStatus(err.response.status, err?.response?.data?.message || err);
          });
      } else {
        throw new ErrorStatus(err.response.status, err?.response?.data?.message || err);
      }
    });
};

const get = <T,>(url: string, body?: Json, headers?: Headers, options?: {}): Promise<T> => (apiClient('GET', url, body, headers, options));
const post = <T,>(url: string, body?: Json, headers?: Headers, options?: {}): Promise<T> => (apiClient('POST', url, body, headers, options));
const put = <T,>(url: string, body?: Json, headers?: Headers, options?: {}): Promise<T> => (apiClient('PUT', url, body, headers, options));
const patch = <T,>(url: string, body?: Json, headers?: Headers, options?: {}): Promise<T> => (apiClient('PATCH', url, body, headers, options));
const del = <T,>(url: string, body?: Json, headers?: Headers, options?: {}): Promise<T> => (apiClient('DELETE', url, body, headers, options));
const getPublic = <T,>(url: string, body?: Json, headers?: Headers, options?: {}): Promise<T> => (apiClient('GET', url, body, headers, options, false, false));
const postPublic = <T,>(url: string, body?: Json, headers?: Headers, options?: {}): Promise<T> => (apiClient('POST', url, body, headers, options, false, false));
const putPublic = <T,>(url: string, body?: Json, headers?: Headers, options?: {}): Promise<T> => (apiClient('PUT', url, body, headers, options, false, false));

// Use this for requests like 'GET /lead-types' which are needed in many places
// but won't realistically change across a session and so can safely be cached:
const getCache: { [key: string]: any } = {};
function getWithCache<T>(url: string): Promise<T> {
  if (getCache[url]) {
    console.log(`Fetching ${url} from cache`);
  } else {
    getCache[url] = get(url).catch((error) => {
      delete getCache[url];
      throw error;
    });
  }

  return getCache[url];
}

async function getWithLongCache<T>(url: string): Promise<T> {
  if (!window.localStorage) {
    return getWithCache(url);
  }

  const key = `getWithLongCache__${url}`;
  const current = window.localStorage.getItem(key);

  if (current) {
    const { value, timestamp } = JSON.parse(current);
    if (value && Date.now() <= timestamp + maxItemAge) {
      return value;
    }
  }

  const fresh: T = await getWithCache(url);
  try {
    window.localStorage.setItem(
      key,
      JSON.stringify({
        value: fresh,
        timestamp: Date.now(),
      }),
    );
  } catch (e) {
    console.warn('Could not cache', url, fresh, e);
  }

  return fresh;
}

export default {
  getWithLongCache,
  getWithCache,
  get,
  post,
  put,
  patch,
  del,
  getPublic,
  postPublic,
  putPublic,
};
